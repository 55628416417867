import React, {FC} from "react";
import './logo-gallery.scss';


interface LogoGalleryProps {
    title?: string,
    images: any,
    className?: string,
}

const LogoGallery: FC<LogoGalleryProps> = (props) => {

    const {images, title, className} = props;

    return (
        <div className={`logo-gallery ${className ? className : ''}`}>
            {title && <span>{title}</span>}
            <div className="logo-wrapper">
                {Object.keys(images).map(key => {
                    return <img className="image" key={key} src={images[key]} alt={key}/>
                })}
            </div>
        </div>
    );
}

export default LogoGallery;