import React from "react";
import {
    Switch,
    Route, Redirect
} from "react-router-dom";
import MainTab from "./mainTab/MainTab";
import UsageConditions from "./usageConditions/usageConditions";

function RouterComponent() {
    return (
        <Switch>
            <Route path='/main' component={MainTab}/>
            <Route path='/usageConditions' component={UsageConditions}/>
            <Redirect
                from="*"
                to="/main"/>
        </Switch>
    );
}

export default RouterComponent;