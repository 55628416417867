import "./usage-conditions.scss"

export default function UsageConditions() {
    return <div className="content usage-conditions">
        <p className="bold"> תנאי שימוש במערכת</p>
        <p>מסמך זה בא להסדיר את המחויביות בין חברת תרומות נט בע"מ המפעילה את מערכת תרומות.נט להלן "תרומות.נט" לבין
            המשתמש. שימוש במערכת תרומות.נט מעיד על הסכמה לתנאים אלו.</p>
        <p>תרומות.נט מתחייבת לאפשר ללקוח שימוש במערכת לצורך ניהול תורמים, הנה"ח, סליקת הוראות קבע, וכו' כפי שמצוין
            במאפייני התוכנה המפורט באתר האינטרנט בכתובת www.trumot.net
            מסמך האפיון המודפס הינו חלק בלתי נפרד מחוזה זה.</p>
        <p>תרומות.נט מתחייבת לשמור על חסיון מלא בכל הנוגע לנתוני הלקוח, נתונים אלו כוללים את בסיס הנתונים של הלקוח
            (תורמים, תלמידים וכו') ואת פעולותיו השונות במערכת, חסיון זה יופר רק בצו בית משפט.</p>
        <p>תרומות.נט מתחיבת לשמור על מאגר הנתונים ע"פ הדרישות האמורות בחוק.</p>
        <p>תרומות.נט מתחייבת להמציא פעם בשנה דיסק הכולל מידע על כל הפעולות שביצע הלקוח בשנה החולפת, זאת ע"פ דרישת מס
            הכנסה, דיסק זה עונה על הצורך לשמירת נתונים שבע שנים</p>
        <p>תרומות.נט מתחייבת לאפשר ללקוח מידע על כל הפעולות שביצע הלקוח בשנה החולפת, זאת ע"פ דרישת מס הכנסה ולגבותו על
            מחשביה.</p>
        <p>תרומות.נט מתחייבת לאפשר ללקוח לשלוף את הנתונים הגולמיים המידע שלו מתוך מאגר הנתונים ברגע בו יבקש זאת הלקוח
            וזאת באמצעות המערכת האינטרנטית.</p>
        <p>נתוני אשראי נשמרים בידי צד ג' בגלל דרישות תקן PCI ( חברת CreditGuard בע"מ) והנתונים יסופקו ללקוח על פי
            דרישתו, ככל שהדבר יתאפשר על ידי החברה הנ"ל.</p>
        <p>במידה ותרומות.נט תחליט להפסיק את פעילות המערכת היא מתחיבת לתת התראה של לפחות 90 יום ללקוח.</p>
        <p>השימוש באינטרנט חושף את המשתמש לסיכונים הנובעים ממבנה רשת האינטרנט ומהשימוש במחשב אישי ו/או כל ציוד קצה אחר
            המאפשר התחברות לרשת (יחד ולחוד - "מחשב אישי"). תשומת לבך מופנית לכך שהנך אחראי/ת לכל התוצאות של שימוש בלתי
            מורשה בסיסמאות ובשאר אמצעי אבטחת המידע שניתנו לך, ובמחשבך האישי. לאור זאת עליך לשמור על הסיסמאות ושאר אמצעי
            אבטחת המידע בסודיות מוחלטת ולמנוע כל שימוש בלתי מורשה בהם ו/או במחשבך האישי.</p>
        <p> תרומות.נט משקיעה מאמצים רבים בהגנה מפני הסיכונים הקיימים בשימוש באינטרנט ובפרט בעסקאות בנקאיות, אך למרות
            זאת, כידוע אין אפשרות לחסימה מוחלטת ו תרומות.נט לא תישא באחריות לנזקים ו/או הפסדים כלשהם העלולים להגרם
            כתוצאה מהסתמכות על המידע המופיע במערכת.</p>
        <p> תרומות.נט אינה אחראית לנזקים כלשהם העלולים להיגרם עקב שימוש במערכת ו/או במידע המוצג בה, שינויים בזמני
            התגובה, חוסר זמינות של המערכת או איזה משירותיה.</p>
        <p> על השימוש במערכת וכל הנובע משימוש כזה יחולו דיני מדינת ישראל</p>
        <p> הלקוח מתחייב לשלם עבור השימוש במערכת ע"פ התעריף המפורסם באתר האינטרנט, התשלום יתבצע בהוראת קבע חודשית בין
            ה-1 לבין ה-7 לחודש עבור החודש הקודם.</p>
        <p> המחירים ושיטת החיוב עשויים להתעדכן מעת לעת, ע"פ מדד המחירים לצרכן ו/או עלות אחזקת השירותים הניתנים ע"י
            תרומות.נט. תרומות נט תודיע ללקוחותיה על העלאת תעריפים חודש מראש.</p>
        <p> תקרת התשלום מתיחסת רק לשירותים הנתנים באופן מלא ע"י תרומות.נט כגון, בנית אנשי קשר, הוצאות קבלות, גבית הוראות
            קבע וכד'. שרותים הניתנים ע"י גורמים חיצוניים כמו משלוח דואר וכד' יחויבו ע"פ שימוש, ללא תקרה.</p>
        <p> במידה והלקוח לא ישלם את תשלומיו במועד, תשלח התראה אחת. במידה והחוב לא ישולם תוך 7 ימים מיום החיוב , תחסם
            ללקוח הגישה למערכת, במקרה כזה לא תתאפשר הוצאת כל מידע מהמערכת.</p>
        <p> הנהלת תרומות.נט</p>
    </div>
}