import {ReactComponent as IconSchool} from "../../assets/img/מוסדות לימוד.svg";
import {ReactComponent as IconTelemarketing} from "../../assets/img/טלמרקטינג.svg";
import {ReactComponent as IconCRM} from "../../assets/img/CRM.svg";
import React, {useState} from "react";
import {Dialog, DialogContent} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import "./products.scss";


const Line = ({text}: { text: string }) => {
    return <div className="line">
        <CheckRoundedIcon/>
        <span>{text}</span>
    </div>
}

interface PopupContent {
    lines: string[],
    bottomSection: any,
    title: string,
    icon: any,
}

const popupContentByName: { [key: string]: PopupContent } = {
    "school":
        {
            lines: [" איך שומרים על אחוזי גבייה גבוהים, תוך עבודה פשוטה ויעילה?",
                " איך יודעים ברגע למי אין אמצעי תשלום פעיל? כמה כסף יכנס החודש? למי יש חוב?",
                " הקמת שנת הלימודים גוזלת זמן רב מחופשת הקיץ?",
                " התעריפים המאושרים לגבייה מגיעים ממשרד החינוך ברגע האחרון?",
                " איך מחשבים בקלות הנחות רטרואקטיביות?",
                " גביית שכר לימוד לילדי הורים פרודים דורשת טיפול מיוחד?",
                " מעוניינים לתת הנחה רק לחלק ממרכיבי תשלומי ההורים?"],
            bottomSection: (<>
                <p>רוצים לשמוע על מגוון פתרונות חדשניים בנושאים אלה ואחרים? <br/>(גם אם כבר יש לכם תוכנת סליקה)</p>
                <p>בואו לשמוע, להתרשם ולהרגיש את המילה האחרונה במערכות הניהול.</p>
            </>),
            title: 'מוסדות לימוד',
            icon: <IconSchool/>
        },
        "telemarketing":
        {
            lines: [" איך אחראי המשמרת יכול לחלק את אנשי הקשר בין הטלפנים, תוך עבודה פשוטה ויעילה?",
                " איך הטלפן יודע ברגע מה היסטורית ההתקשרות עם התורם?",
                " חישוב הבונוסים של הטלפנים דורש הרבה זמן בכל חודש?",
                " התורם ביקש לחזור אליו בעוד שבועיים? הוא מדבר רק צרפתית? יש לו יום הולדת?",
                " איך דואגים שתורם לא יקבל שיחות ממספר טלפנים שונים?",
                " מי מהטלפנים מרוויח יותר מהתרומות שהוא מגייס? ואיך מזהים טלפן איכותי?",
                " מה עושים עם כל הידע שנשמר אצל טלפן שעוזב?",
                ],
                bottomSection: (<>
                    <p>רוצים לשמוע על מגוון פתרונות חדשניים בנושאים אלה ואחרים? <br/>(גם אם כבר יש לכם מרכזיה)</p>
                    <p>בואו לשמוע, להתרשם ולהרגיש את המילה האחרונה במערכות הניהול.</p>
            </>),
            title: 'טלמרקטינג',
            icon: <IconTelemarketing/>
        },
        "CRM":
        {
            lines: [" הסנכרון בין כל המערכות של הארגון גוזל הרבה משאבים?",
                " איך חוסכים זמן יקר על ניהול של הרבה טבלאות אקסל בתהליכי גיוס הכספים?",
                " איך מעבירים את כל המידע הארגוני לתוכנה אחת בצורה מהירה וחלקה?",
                " קשה להשיג דוחות מרואה החשבון לפני כל ישיבת הנהלה?",
                " מעוניינים להימנע מבלגאן ובירוקרטיה בגביה שעלולים לפגוע באמון של התורמים?",
                " פילוח חכם של אנשי קשר היא משימה מורכבת?",
                ],
                bottomSection: (<>
                    <p>רוצים לשמוע על מגוון פתרונות חדשניים בנושאים אלה ואחרים? <br/>(גם אם כבר יש לכם תוכנת סליקה)</p>
                    <p>בואו לשמוע, להתרשם ולהרגיש את המילה האחרונה במערכות הניהול.</p>
            </>),
            title: 'ניהול מערך תורמים',
            icon: <IconCRM/>
        }
}

export default function Products() {

    const [popupContentName, setPopupContentName] = useState<string | null>(null);
    const closeDialog = () => {
        setPopupContentName(null);
    }

    return <div className="products">
        <div className="header"> הפתרונות שלנו:</div>
        <div className="icon-wrapper">
            {Object.keys(popupContentByName).map(key=>{
                return <div id={key} key={key} onClick={() => setPopupContentName(key)}>
                    {popupContentByName[key].title}
                    {popupContentByName[key].icon}
                </div>
            })}
        </div>

        <Dialog className="product-popup" open={popupContentName != null} onClose={closeDialog}>
            {popupContentName && <DialogContent dir="rtl">
                {popupContentByName[popupContentName].lines.map(line => <Line key={line} text={line}/>)}
                {popupContentByName[popupContentName].bottomSection}
            </DialogContent>}
        </Dialog>
    </div>
}