import LogoGallery from "./logoGallery/LogoGallery";
import React, {useEffect, useMemo} from "react";
import MovieScroller from "./movieScroller/MovieScroller";
import './main-tab.scss';
import ContactUs from "../contactUs/ContactUs";
import Products from "./products/Products";
import Benefits from "./benefits/Benefits";
import Advantages from "./advantages/Advantages";
import Divider from "./Divider";
import {importAll} from "../utils/trumotUtils";

const MainTab = () => {
    useEffect(() => {

    }, [])

    const customerLogos = useMemo(() => importAll(require.context("../assets/img/customersLogos", false, /\.(png|jpe?g|svg)$/)), []);
    const systemLogos = useMemo(() => importAll(require.context("../assets/img/systemLogos", false, /\.(png|jpe?g|svg)$/)), []);

    return <div className="main-tab">
        <div className="blue-box-wrapper">
            <div className="blue-box">
                <div className="large-font">
                    <span>תרומות.נט</span>
                    , ממערכות הגבייה המתקדמות בישראל, מציגה בפניכם את הפתרונות החדשניים ביותר של
                    <span className="color"> ניהול לקוחות אסטרטגי.</span>
                </div>
                אנחנו בתרומות.נט, מתמחים בפתרונות שיאפשרו לכם להתעסק נטו בפעילות של הארגון.<br/>
                מערכות הניהול שלנו יאפשרו לכם פניות כדי לעשות יותר בשביל העמותה.
            </div>
        </div>

        <Products/>
        <Benefits/>
        <Advantages/>
        <Divider/>
        <div className="bit">
            <div className="text">
                <div className="bit-logo"/>
                <span>גם אתם רוצים לסלוק ב-BIT? <a href={"/main#contactUs"}>השאירו פרטים</a> ונחזור אליכם בהקדם!</span>
            </div>
            <LogoGallery images={systemLogos} className="systemLogos"/>
        </div>
        <LogoGallery images={customerLogos} title="מלקוחותינו:" className="customers"/>
        <div className="skew-top">
            <MovieScroller/>
        </div>
        <ContactUs/>
    </div>
}

export default MainTab;