import React, {useState} from "react";
import {ReactComponent as Logo} from '../assets/img/logo.svg';
import TopMenu from "./TopMenu";
import './header.scss';

export default function AppHeader() {

    const [clicked, setClicked] = useState(false);
    let classBounce = ("App-logo bounce") + (clicked ? " bouncing" : "");
    const logoClick = (event) => {
        setClicked(true);
        setTimeout(() => {
            setClicked(false)
        }, 1100);
    };


    return <>
        <header className="App-header">
            <div className="text-wrapper">
                <a className="animation-container">
                    <Logo className={classBounce} onClick={logoClick}/>
                </a>
                <span className="name blue">תרומות<span className="gray">.</span>נט</span>
                <span className="slogan">הניסיון שלנו -
                                <span className="blue"> הרווח שלך</span>
                            </span>
            </div>
        </header>
        <div className="menu">
            <TopMenu/>
        </div>
    </>
}