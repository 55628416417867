import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import "./benefits.scss";
import React from "react";

const Bullet = ({text}: { text: string }) => {
    return <div className="Bullet">
        <CheckRoundedIcon/>
        <div>{changeRegexToBold(text, /\*.*?\*/)}</div>
    </div>
}

const changeRegexToBold = (str: string, regex: RegExp) => {
    const arr: any[] = [];
    let execArray: RegExpExecArray | null;
    while ((execArray = regex.exec(str)) !== null) {
        arr.push(str.substring(0, execArray.index));
        let result = execArray[0];
        arr.push(<span key={result} className="bold">{result.substring(1, result.length - 1)}</span>);
        str = str.substring(execArray.index + result.length, str.length);
    }
    arr.push(str);
    return arr;
}

const lines = [
    "לנהל את *מערך הגביה* של הארגון שלי *מפלטפורמה אחת*, ולהשתמש במערכת חכמה שתעשה סדר בגבייה.",
    "להכניס אוטומציה בתהליכים שגרתיים *ולנצל בצורה מיטבית את כח האדם.*",
    "לייצר כלי שיציג את הצפי הכספי של הארגון בכל רגע, *ולנהל את התקציב בצורה אופטימלית.*",
    "להפסיק לדאוג לגבי הנתונים שלי, ולדעת שהמידע *מאובטח, וזמין מכל מקום ובכל זמן.*",
    "להפוך כל יום עבודה להרבה יותר *יעיל ואפקטיבי.*"
]

const Benefits = () => {

    return <div className="asymmetric-wrapper">
        <div className="benefits">
            <div className="bold-header">הלקוחות שלנו לא צריכים לסנכרן בין הרבה תוכנות.<br/>
                איתנו, תוכלו להנות ממערכת אחת שממנה מנוהלת כל הפעילות הכספית.
            </div>
            <div className="colored-header">באמצעות המערכת יתאפשר לי:</div>
            {lines.map((line) => <Bullet key={line} text={line}/>)}
            <div className="end-line">המטרה שעומדת לנגד עינינו היא שלקוחותינו יתייעלו, יצמחו, וירוויחו זמן יקר לטובת מימוש
                המשימות שלהם.
            </div>
        </div>
    </div>
}

export default Benefits;