import React, {FC, useCallback} from "react";
import {Tab, Tabs} from "@mui/material";
import {useHistory, useLocation} from "react-router";
import LockIcon from '@mui/icons-material/Lock';

const TopMenu: FC = () => {
    const location = useLocation();
    const value = location.pathname;

    const history = useHistory();
    const handleChange = useCallback((event: React.ChangeEvent<any>, choice: string) => {
        if (choice) {
            history.push({
                pathname: choice,
                search: window.location.search
            });
        }
    }, [history]);

    return (
        <>
            <Tabs value={value} onChange={handleChange}>
                <Tab label="דף הבית" value="/main"/>
                <Tab label="תנאי שימוש" value="/usageConditions"/>
            </Tabs>
            <button className="login" onClick={() => window.location.href = "https://www.trumot.net/first.htm"}>
                <LockIcon/><span>התחבר</span>
            </button>
        </>
    )
        ;
}
export default TopMenu;