import React, {FC, useEffect, useState} from "react";
import './movie-scroller.scss';
import ImageGallery from 'react-image-gallery';
import {importAll} from "../../utils/trumotUtils";

const MovieScroller: FC = () => {
    const [videoReferenceObj] = useState<any>({});
    const [movies, setMovies] = useState<any[]>([]);
    useEffect(() => {
        (async function initMovies() {
            let storageChanged = false;
            const cachedMovies = JSON.parse(localStorage.getItem('movies') ?? '[]');
            let cachedMovieByName = cachedMovies.reduce((r, e) => (r[e.name] = e, r), {});
            // console.log('cachedMovieByName', cachedMovieByName)
            const allMovies = importAll(require.context("../../assets/movies", false));
            console.log(allMovies);
            let movieNames = Object.keys(allMovies);
            const movies: any[] = [];
            for (const movieName of movieNames) {
                let videoCover;
                if (cachedMovieByName[movieName]) {
                    videoCover = cachedMovieByName[movieName].thumbnail;
                } else {
                    try {
                        videoCover = await getVideoCover(allMovies[movieName]);
                    } catch (ex) {
                        console.error(ex);
                    }
                    storageChanged = true;
                }
                movies.push({
                    name: movieName,
                    // original: videoCover,
                    thumbnail: videoCover,
                    embedUrl: allMovies[movieName],
                    renderItem: _renderVideo,
                });
            }
            if (storageChanged) {
                localStorage.setItem('movies', JSON.stringify(movies));
            }
            setMovies(movies);
        })()
    }, [])


    const onSlide = () => {
        Object.keys(videoReferenceObj).forEach(key => videoReferenceObj[key].pause());
    }

    function getVideoCover(file, seekTo = 0.0) {
        // console.log("getting video cover for file: ", file);
        return new Promise((resolve, reject) => {
            // load the file to a video player
            const videoPlayer = document.createElement('video');
            videoPlayer.setAttribute('src', file);
            videoPlayer.load();
            videoPlayer.addEventListener('error', (ex) => {
                reject("error when loading video file");
            });
            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadedmetadata', () => {
                // seek to user defined timestamp (in seconds) if possible
                if (videoPlayer.duration < seekTo) {
                    reject("video is too short.");
                    return;
                }
                // delay seeking or else 'seeked' event won't fire on Safari
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo;
                }, 10);
                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    // console.log('video is now paused at %ss.', seekTo);
                    // define a canvas to have the same dimension as the video
                    const canvas = document.createElement("canvas");
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;
                    // draw the video frame to canvas
                    const ctx = canvas.getContext("2d");
                    if (ctx) {
                        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                        // return the canvas image as a blob
                        let message = ctx.canvas.toDataURL('image/jpeg', 0.1);
                        resolve(message);
                    }
                });
            });
        });
    }

    function _renderVideo(item) {
        return (
            <div>
                <div className='movie-wrapper'>
                    <video ref={el => videoReferenceObj[item.embedUrl] = el} className="movie" controls>
                        <source src={item.embedUrl} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        );
    }

    return <div className="movie-scroller-wrapper">
        <ImageGallery items={movies} onSlide={onSlide} showPlayButton={false} showFullscreenButton={false}/>
    </div>
}
export default MovieScroller;