import React from "react";
import "./advantages.scss"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import BalanceIcon from "@mui/icons-material/Balance";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const Advantages = () => {

    return <div className="advantages">
        <div className="box">
            <CurrencyExchangeIcon/>
            <div className="bold">פתרון שנותן תנועה מתוכננת של כספים</div>
            לתכנון נכון של תקציב גם בארגונים מורכבים.
        </div>
        <div className="box">
            <BalanceIcon/>
            <div className="bold">
                לעקוב בקלות אחרי המצב הכספי
            </div>
            מתוך מגוון של דו"חות מפורטים.
        </div>
        <div className="box">
            <AutoAwesomeIcon/>
            <div className="bold">דפי נחיתה מעוצבים לקבלת תשלומים ותרומות</div>
            ללא הגבלה, זמינים בכל זמן ומכל מקום.
        </div>
    </div>
}

export default Advantages;