import React, {useState} from 'react';
import {SnackbarProvider} from 'notistack';
import './App.scss';
import RouterComponent from "./RouterComponent";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import rtlPlugin from "stylis-plugin-rtl";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import {prefixer} from "stylis";
import {SnackbarUtilsConfigurator} from "./SnackbarUtils";
import Footer from "./footer/Footer";
import AppHeader from "./header/AppHeader";

function App() {

    const [isRtl, setIsRtl] = useState(true);
    const cacheLtr = createCache({
        key: "muiltr"
    });

    const cacheRtl = createCache({
        key: "muirtl",
        // prefixer is the only stylis plugin by default, so when
        // overriding the plugins you need to include it explicitly
        // if you want to retain the auto-prefixing behavior.
        stylisPlugins: [prefixer, rtlPlugin]
    });
    React.useLayoutEffect(() => {
        document.getElementById('App')?.setAttribute("dir", isRtl ? "rtl" : "ltr");
    }, [isRtl]);
    const ltrTheme = createTheme({direction: "ltr"});
    const rtlTheme = createTheme({direction: "rtl"});

    return (
        <div id="App">
            <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
                <ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
                    <SnackbarProvider maxSnack={3}
                                      anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                      }}
                    >
                        <SnackbarUtilsConfigurator/>
                        <AppHeader/>
                        <div className="App-body">
                            <div className="content">
                                <RouterComponent/>
                            </div>
                            <Footer/>
                        </div>
                    </SnackbarProvider>
                </ThemeProvider>
            </CacheProvider>
        </div>
    );
}

export default App;
