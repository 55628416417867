import React, {useState} from "react";
import * as ReactDOMServer from 'react-dom/server';
import './contact.scss'
import {TextField, Tooltip} from "@mui/material";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailIcon from '@mui/icons-material/Mail';
import SnackbarUtils from "../SnackbarUtils";

type FormData = {
    name: string,
    email: string,
    phone: string,
    organization: string,
    text: string,
}
const ContactUs = () => {
    const [formData, setFormData] = useState<FormData>({name: '', email: '', phone: '', organization: '', text: ''});

    const handleChangeVal = (val, fieldName) => {
        setFormData((oldFormData) => {
            const newFormData = {...oldFormData};
            newFormData[fieldName] = val.target.value;
            return newFormData;
        });
    }

    const sendFormData = () => {

        const contactNameString = formData.name ? formData.name : "איש קשר";
        const contactOrganizationString = formData.organization ? " מעמותת '" + formData.organization + "'" : "";

        const emailBody = <div style={{direction: "rtl"}}>
            <p style={{marginBottom: 40}}>שלום.</p>
            <p>{contactNameString}{contactOrganizationString} השאיר לך פרטים בדף הנחיתה של תרומות.</p>
            {formData.text &&
            <p>הוא צירף את ההודעה הבאה: "<span style={{fontWeight: "bold"}}>{formData.text}</span>"</p>}
            <p style={{marginBottom: 40}}>הוא ישמח שיצרו איתו קשר
                {formData.phone && <span> בטלפון מס' <a href={`tel: ${formData.phone}`}>{formData.phone}</a></span>}
                {(formData.phone && formData.email) && <span> או</span>}
                {formData.email &&
                <span> בכתובת <a href={`mailto:${formData.email}`} target="_blank">{formData.email}</a></span>}
            </p>
            <p>המשך יום נעים!</p>
            <p>מערכת תרומות.נט</p>
        </div>

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "lead_name": formData.name,
            "lead_email": formData.email,
            "lead_phone": formData.phone,
            "lead_organization": formData.organization,
            "email_body": ReactDOMServer.renderToString(emailBody),
            "email_subject": "יש לך הודעה חדשה מדף הנחיתה של תרומות.נט!",
            "is_email_body_html": true
        });

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://trumot.net/api/submitLeadForm", requestOptions)
            .then(response => response.text())
            .then(() => {
                SnackbarUtils.success('ההודעה נשלחה בהצלחה');
            })
            .catch(error => {
                console.log('error', error);
                SnackbarUtils.error('חלה שגיאה בשליחת ההודעה. נסה שנית מאוחר יותר.')
            });
    }


    const validateForm = () => {
        return (formData.name === '' && formData.organization === '') || (formData.phone === '' && formData.email === '');
    };

    return <div id='contactUs' className="contact-us">
        <span className="header">השאר פרטים ונחזור אליך בהקדם</span>
        <div className="contact-form" dir="rtl">
            <div className="small">
                <TextField
                    variant="filled"
                    label="שם"
                    defaultValue={formData.name}
                    onChange={(val) => handleChangeVal(val, 'name')}
                />
                <TextField
                    variant="filled"
                    label="שם הארגון"
                    defaultValue={formData.organization}
                    onChange={(val) => handleChangeVal(val, 'organization')}
                />
                <TextField
                    type='tel'
                    variant="filled"
                    label="טלפון"
                    defaultValue={formData.phone}
                    onChange={(val) => handleChangeVal(val, 'phone')}
                />
                <TextField
                    type='email'
                    variant="filled"
                    label="מייל"
                    defaultValue={formData.email}
                    onChange={(val) => handleChangeVal(val, 'email')}
                />
            </div>
            <div>
                <TextField
                    fullWidth
                    type='text'
                    variant="filled"
                    label="רוצה להוסיף משהו?"
                    multiline
                    minRows={3}
                    defaultValue={formData.text}
                    onChange={(val) => handleChangeVal(val, 'text')}
                />
            </div>
        </div>
        <Tooltip title={validateForm() ? 'לשליחת הטופס הכנס שם או שם ארגון ופרטי יצירת קשר' : ''} enterTouchDelay={0}
                 leaveTouchDelay={3000}>
            <div className="actions">
                <button onClick={() => sendFormData()} disabled={validateForm()}>שלח</button>
            </div>
        </Tooltip>

        <div className="trumot-contact">
            <a href={`tel: 0525340007`}>
                <span>
                    <PhoneIphoneIcon fontSize="large"/>
                    טלפון: 0525340007
                </span>
            </a>
            <span className="circle"/>
            <a href={`mailto:support@trumot.net`} target="_blank">
                <span>
                    <MailIcon fontSize="large"/>
                    מייל: contact@trumot.net
                    </span>
            </a>
        </div>
    </div>
}

export default ContactUs;